import React, { useState, Suspense } from "react"
import view from "./vehiclecompare.module.scss"
import { Link } from "gatsby";
import useModal from "../../hooks/useModal"
import HTML from "../../components/HTML"

function VehicleCompare(props) {
    const {Vehicle, Page} = props.data
    const {thumbnails} = props
    const mainVehicleURL = props.mainVehicle
    const selectedVehicleURL = props.selectedVehicle

    const comparesList = thumbnails.filter((item) => {
       return item.path === mainVehicleURL
    })[0].compares

    const isSelected = (vehicle) => {
        return vehicle.url === selectedVehicleURL;
    }

    const selectedVehicle = Vehicle.vehicleCompare.find(isSelected)

    const selectedVehicleObj = {
        jellybean: selectedVehicle.compareJellybean,
        name: selectedVehicle.name,
        url: selectedVehicle.url
    }
    const mainVehicle = {
        jellybean: Vehicle.jellybean,
        name: Vehicle.name,
        url: Vehicle.url
    }
    const textCompare = {
        title: selectedVehicle.textCompare[0].comparisonTextTitle,
        lines: selectedVehicle.textCompare[0].comparisonLines,
        order: selectedVehicle.textCompare[0].order,
        visible: selectedVehicle.textCompare[0].visible
    }
    const rateCompare = {
        title: selectedVehicle.rateCompare[0].comparisonRateTitle,
        lines: selectedVehicle.rateCompare[0].rateLines,
        order: selectedVehicle.rateCompare[0].order,
        visible: selectedVehicle.rateCompare[0].visible
    }
    const checkCompare = {
        title: selectedVehicle.checkCompare[0].comparisonCheckTitle,
        lines: selectedVehicle.checkCompare[0].comparisonVehicleChecks,
        order: selectedVehicle.checkCompare[0].order,
        visible: selectedVehicle.checkCompare[0].visible
    }
    const [renderMainVehicleModal, visibleMVM] = useModal({
        title: "Select Vehicle",
        location: "#main"
    })
    const [renderCompareVehicleModal, visibleCVM] = useModal({
        title: "Select Vehicle",
        location: "#compare"
    })


    const mainVehicleModal = typeof window !== 'undefined' &&
    (
      <Suspense fallback={<div></div>}>
        <div className={view["vehicle-modal"]}>
            {thumbnails.map(item => {
                return(
                    item.showModel === true ? <Link className={view["vehicle-link"]} to={item.compares[0].path}><img src={item.thumbnail} /><span>{item.name}</span></Link> : <span></span>
                )
            })}
        </div>
      </Suspense>
    )

    const compareVehicleModal = typeof window !== 'undefined' &&
    (
      <Suspense fallback={<div></div>}>
        <div className={view["vehicle-modal"]}>
            {comparesList.map(item => {
                return(
                    <Link className={view["vehicle-link"]} to={item.path}>
                        <img src={item.thumbnail} />
                        <span>{item.name}</span>
                    </Link>
                )
            })}
        </div>
      </Suspense>
    )

    const returnHeader = () => {
        return(
            <div className={view["compare-header"]}>
                <div className={view["ribbon"]}>
                    <h2 className={view["main-vehicle"]}>{mainVehicle.name}</h2>
                    <p className={view["mid-section"]}>SELECT MODELS TO COMPARE</p>
                    <h2 className={view["compare-vehicle"]}>{selectedVehicleObj.name}</h2>
                </div>

                <div className={view["header-background"]} style={{backgroundImage: "url(" + Page.bg + ")"}}>
                    <div className={view["main-jellybean"]} >
                        <img src={mainVehicle.jellybean} alt="Main Vehicle Jellybean" />
                        <p style={{fontSize: "10px", color: "rgba(255,255,255,0.35)", display: "block", margin: "0 auto", width: "100%", textAlign: "center", padding: "0 0 10px"}}>*Image for illustrative purposes only</p>
                        <Link to="#main" ><button>Change Vehicle</button></Link>
                    </div>
    
                    <div className={view["center-vs"]}></div>
    
                    <div className={view["compare-jellybean"]}>
                        <img src={selectedVehicleObj.jellybean} alt="Comparison Vehicle Jellybean" />
                        <span style={{height: "20px", display: "block", width: "100%"}}></span>
                        <Link to="#compare" ><button>Change Vehicle</button></Link>
                    </div>
                </div>
            </div>
        )
    }

    const returnModal = () => {
        return(
            <>
                {renderMainVehicleModal(mainVehicleModal)}
                {renderCompareVehicleModal(compareVehicleModal)}
            </>
        )
    }

    let components = [
        <TextCompare data={{ 
            order: textCompare.order,
            visible: textCompare.visible,
            textCompare: textCompare
        }} key={"TextCompare"} />,
        <RateCompare data={{ 
            order: rateCompare.order,
            visible: rateCompare.visible,
            rateCompare: rateCompare
        }} key={"RateCompare"} />,
        <CheckCompare data={{ 
            order: checkCompare.order,
            visible: checkCompare.visible,
            checkCompare: checkCompare
        }} key={"CheckCompare"} />
    ]

    if(props.data.hasOwnProperty("ArrayRawHTML")) {
        const { arrayRawHTML } = props.data.ArrayRawHTML
        const rawComponents = arrayRawHTML.map(element => {  
            return <HTML data={{ 
                order: Number(element.order),
                visible: element.visible,
                rawHTML: element.rawHtml
            }} key={"HTML"} />
        })
        components.push(...rawComponents)
      }
  
      let sortedComponents = components.sort((a, b) =>
          parseFloat(a.props.data.order) - parseFloat(b.props.data.order)
      ) // sorts the components based on json data
      // filters out the components that are not visible
      let visibleComponents = sortedComponents.filter(component => {
          return component.props.data.visible === true
      })

    //order and visibility sorting
    const returnVisibleElements = () => {
        return(
            <>
            {returnHeader()}
            { visibleComponents }
            {returnModal()}
            </>
        )
    }
    return (
        <>
            {returnVisibleElements()}
        </>
    )
}
export default VehicleCompare


//Section Components

function TextCompare(props) {
    const {textCompare} = props.data

    return(
        <div className={view["text-compare"]}>
            {textCompare.title != '' ? 
                <h4 className={view["compare-title"]}>{textCompare.title}</h4> : <span></span>
            }
            {textCompare.lines.map(line => {
                return(
                    <div className={view["compare-line"]}>
                        <p className={view["main-value"]}>{line.mainVehicleValue}</p>
                        <div className={view["middle-column"]}>
                            <p className={view["middle-title"]}>{line.comparisonLineTitle}</p>
                            <p className={view["middle-subtitle"]}>{line.comparisonLineSubtitle}</p>
                        </div>
                        <p className={view["comparison-value"]}>{line.comparisonVehicleValue}</p>
                    </div>
                )
            })}
        </div>
    )
}

function RateCompare(props) {
    const {rateCompare} = props.data

    const generateStars = (value) => {
        let resultsArray = []
        if(value.toString() === "N/A" || value.toString() === "") {
            resultsArray.push(<span className={view["no-rating"]}>Not Rated</span>)
        }
        else if(value > 0 && value <= 5) {
            for(let i = 0; i < value; i++) {
                resultsArray.push(<span className={view["star"]}></span>)
            }
        }
        else {
            resultsArray.push(<span></span>)
        }
        return resultsArray;
    }

    return(
        <div className={view["rate-compare"]}>
            {rateCompare.title != '' ? 
            <h4 className={view["compare-title"]}>{rateCompare.title}</h4> : <span></span> 
            }
            {rateCompare.lines.map(line => {
                return(
                    <div className={view["compare-line"]}>
                        <p className={view["main-value"]}>{generateStars(line.mainVehicleStars)}</p>
                        <div className={view["middle-column"]}>
                            <p className={view["middle-title"]}>{line.comparisonLineTitle}</p>
                            <p className={view["middle-subtitle"]}>{line.comparisonLineSubtitle}</p>
                        </div>
                        <p className={view["comparison-value"]}>{generateStars(line.comparisonVehicleStars)}</p>
                    </div>
                )
            })}
        </div>
    )
}

function CheckCompare(props) {
    const {checkCompare} = props.data
    return(
        <div className={view["check-compare"]}>
            {checkCompare.title != '' ? 
                <h4 className={view["compare-title"]}>{checkCompare.title}</h4> : <span></span>
            }
            {checkCompare.lines.map(line => {
                return(
                    <div className={view["compare-line"]}>
                        <div className={view["main-value"]}>
                            <span className={view[line.mainVehicleCheckbox.toString()  === "true" ? "true" : "false"]}></span>
                            <span className={view["check-subtitle"]}>{line.mainCheckSubtitle}</span>
                        </div>
                        <div className={view["middle-column"]}>
                            <p className={view["middle-title"]}>{line.comparisonLineTitle}</p>
                            <p className={view["middle-subtitle"]}>{line.comparisonLineSubtitle}</p>
                        </div>
                        <div className={view["comparison-value"]}>
                            <span className={view[line.comparisonVehicleCheckbox.toString() === "true" ? "true" : "false"]}></span>
                            <span className={view["check-subtitle"]}>{line.compareCheckSubtitle}</span>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}